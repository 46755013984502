var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-aboutCompany",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "company",
    attrs: {
      id: "about_company"
    }
  }, [_vm.device == "pc" ? _c("div", {
    staticClass: "bannerTop"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    style: {
      height: _vm.device == "pc" ? "" : 230 + "px"
    },
    attrs: {
      src: require("../assets/banner/about_company_banner_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    style: {
      height: _vm.device == "pc" ? "" : 230 + "px"
    },
    attrs: {
      src: require("../assets/banner/about_company_banner_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    style: {
      height: _vm.device == "pc" ? "" : 230 + "px"
    },
    attrs: {
      src: require("../assets/banner/about_company_banner_tr.jpg")
    }
  }) : _vm._e()]) : _vm._e(), _vm.device == "mobile" ? _c("div", {
    staticClass: "bannerTop"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/banner/about_company_banner_mobile_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/banner/about_company_banner_mobile_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/banner/about_company_banner_mobile_tr.jpg")
    }
  }) : _vm._e()]) : _vm._e()]), _c("div", {
    staticClass: "content company-intro"
  }, [_c("div", {
    staticClass: "content-header"
  }, [_c("div", {
    staticClass: "concept-content",
    attrs: {
      id: "company_intro"
    }
  }, [_c("div", {
    staticClass: "left animate__animated animate__fadeInLeft animate__slow",
    class: {
      "left-en": _vm.language == 2
    }
  }, _vm._l(_vm.aLinks, function (item, index) {
    return _c("a", {
      key: index,
      staticClass: "about-navItem",
      class: {
        current: index == _vm.currentIndex
      },
      on: {
        click: function ($event) {
          return _vm.scrollIntoView(item.id, index);
        }
      }
    }, [_vm._m(0, true), _c("span", [_vm._v(_vm._s(item.name[parseInt(_vm.language) - 1]))])]);
  }), 0), _c("div", {
    staticClass: "right animate__animated animate__fadeInUp animate__slow",
    style: {
      maxHeight: _vm.device == "pc" && parseInt(_vm.language) === 2 ? 27 + "rem" : ""
    }
  }, [_c("p", {
    staticClass: "intro-content",
    class: {
      expand: _vm.isExpand
    },
    attrs: {
      id: "incontent"
    },
    domProps: {
      innerHTML: _vm._s(_vm.about_company.introduce)
    }
  }), _c("div", {
    staticClass: "button",
    on: {
      click: function ($event) {
        _vm.isExpand = !_vm.isExpand;
      }
    }
  }, [_vm._v(_vm._s(!_vm.isExpand ? _vm.mapText.viewMore[parseInt(_vm.language) - 1] : _vm.mapText.stow[parseInt(_vm.language) - 1])), _c("i", {
    staticClass: "el-icon-d-arrow-left",
    class: {
      "icon-stow": _vm.isExpand
    }
  })])])])])]), _c("div", {
    staticClass: "partition img-01"
  }, [_c("img", {
    style: {
      height: _vm.device == "pc" ? "" : 140 + "px"
    },
    attrs: {
      src: require("../assets/about_company/partition_img01.jpg"),
      alt: ""
    }
  })]), _c("div", {
    ref: "sense_of_worth",
    staticClass: "sense-of-worth",
    attrs: {
      id: "sense_of_worth"
    }
  }, [_c("div", {
    staticClass: "circle"
  }), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    ref: "sense_of_worth_left",
    staticClass: "left animate__animated animate__slow display-none"
  }, [_c("p", [_vm._v(_vm._s(_vm.mapText.sense_of_worth_title[parseInt(_vm.language - 1)]))])]), _c("div", {
    ref: "sense_of_worth_right",
    staticClass: "right animate__animated animate__slow display-none",
    class: {
      "right-en": _vm.language == 2
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.mapText.sense_of_worth_valuechain[parseInt(_vm.language - 1)]) + " "), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    staticClass: "text",
    class: {
      "text-en": _vm.language == 2
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.mapText.sense_of_worth_integrity[parseInt(_vm.language - 1)]))]), _c("p", [_vm._v(_vm._s(_vm.mapText.sense_of_worth_passion[parseInt(_vm.language - 1)]))]), _c("p", [_vm._v(_vm._s(_vm.mapText.sense_of_worth_execute[parseInt(_vm.language - 1)]))]), _c("p", [_vm._v(_vm._s(_vm.mapText.sense_of_worth_create[parseInt(_vm.language - 1)]))]), _c("p", [_vm._v(_vm._s(_vm.mapText.sense_of_worth_synergy[parseInt(_vm.language - 1)]))])])])])]), _vm._m(1), false ? _c("div", {
    staticClass: "development-history-pre"
  }, [_c("p", [_vm._v("2015年诺辉健康成立于浙江杭州，是中国首家专注于高发癌症居家早筛的生物高科技公司，也领先行业首个实现香港联交所成功上市，成为“中国癌症早筛第一股”，股票代码6606.HK。")]), _c("p", [_vm._v("以“改变生命的轨迹”为使命，诺辉健康坚持科学有依据、临床能验证、用户有需求、市场有前景的产品理念，大力投资于自主研发的多组学癌症筛查技术创新与产品开发。针对中国目前最高发的消化道癌症中的结直肠癌和胃癌，公司已经分别上市并商业化三款业内首创明星产品：常卫清®、噗噗管®和幽幽管®。")])]) : _vm._e(), _vm.device == "pc" ? _c("div", {
    ref: "development_history",
    staticClass: "development-history",
    attrs: {
      id: "development_history"
    }
  }, [_c("div", {
    ref: "development_history_left",
    staticClass: "left animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.mapText.development_history_title[parseInt(_vm.language - 1)]))]), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    ref: "development_history_center",
    staticClass: "time-content animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.yearEvent.year))]), _c("div", {
    staticClass: "event-list"
  }, [_c("el-timeline", _vm._l(_vm.yearEvent.details, function (item, i) {
    return _c("el-timeline-item", {
      key: i,
      attrs: {
        placement: "top"
      }
    }, [_c("el-card", [_c("h4", [_vm._v(_vm._s(item.month) + _vm._s(_vm.language == 1 || _vm.language == 3 ? "月" : ""))]), _c("div", {
      domProps: {
        innerHTML: _vm._s(item.context)
      }
    })])], 1);
  }), 1)], 1)]), _c("div", {
    ref: "development_history_right",
    staticClass: "time-line animate__animated animate__slow display-none"
  }, _vm._l(_vm.development, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "time-block",
      class: {
        active: index == _vm.activeIndex
      },
      on: {
        click: function ($event) {
          return _vm.checkYearEvent(item, index);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(item.year))])]);
  }), 0)]) : _vm._e(), _vm.device == "mobile" ? _c("div", {
    ref: "development_history",
    staticClass: "development-history",
    attrs: {
      id: "development_history"
    }
  }, [_c("div", {
    ref: "development_history_left",
    staticClass: "development-header animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.mapText.development_history_title[parseInt(_vm.language - 1)]))]), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    ref: "development_history_center",
    staticClass: "time-content animate__animated animate__slow display-none"
  }, [_vm.device == "pc" ? _c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.yearEvent.year))]) : _vm._e(), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    ref: "development_history_right",
    staticClass: "time-line animate__animated animate__slow display-none"
  }, _vm._l(_vm.development, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "time-block",
      class: {
        active: index == _vm.activeIndex
      },
      on: {
        click: function ($event) {
          return _vm.checkYearEvent(item, index);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(item.year))])]);
  }), 0), _c("div", {
    staticClass: "event-list"
  }, [_c("el-timeline", _vm._l(_vm.yearEvent.details, function (item, i) {
    return _c("el-timeline-item", {
      key: i,
      attrs: {
        placement: "top"
      }
    }, [_c("h4", [_vm._v(_vm._s(item.month) + _vm._s(_vm.language == 1 || _vm.language == 3 ? "月" : ""))]), _c("el-divider"), _c("div", {
      domProps: {
        innerHTML: _vm._s(item.context)
      }
    })], 1);
  }), 1)], 1)])])]) : _vm._e(), _c("div", {
    ref: "management_layer",
    staticClass: "management-layer",
    attrs: {
      id: "management_layer"
    }
  }, [_c("div", {
    ref: "management_layer_title",
    staticClass: "left animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.mapText.management_layer[parseInt(_vm.language - 1)]))]), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    ref: "management_layer_content",
    staticClass: "management-intro animate__animated animate__slow display-none"
  }, [_c("el-row", {
    attrs: {
      gutter: 40
    }
  }, _vm._l(_vm.managementList, function (items, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: _vm.device == "pc" && (index < 3 || index >= 7) ? 8 : _vm.device == "pc" && index >= 3 && index <= 6 ? 6 : 24
      }
    }, [_c("img", {
      staticClass: "headImg",
      attrs: {
        src: _vm.title + "uploadImage/" + items.headImage
      }
    }), _c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(_vm.language == 1 ? items.cnName : _vm.language == 2 ? items.enName : items.trName))]), _c("div", {
      staticClass: "position",
      class: {
        "position-en": _vm.language == 2
      }
    }, [_vm._v(" " + _vm._s(_vm.language == 1 ? items.cnPosition : _vm.language == 2 ? items.enPosition : items.trPosition) + " ")]), _vm.device == "pc" ? [_c("div", {
      staticClass: "introduce"
    }, [_vm._v(_vm._s(_vm.language == 1 ? items.cnIntroduction : _vm.language == 2 ? items.enIntroduction : items.trIntroduction))])] : _vm._e(), _vm.device == "mobile" ? [_c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !items.showIntroduce,
        expression: "!items.showIntroduce"
      }],
      on: {
        click: function ($event) {
          return _vm.readMore(index, 1);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-bottom"
    }), _vm._v(_vm._s(_vm.mapText.viewMore[parseInt(_vm.language - 1)]))]), items.showIntroduce ? _c("div", {
      staticClass: "introduce"
    }, [_vm._v(_vm._s(_vm.language == 1 ? items.cnIntroduction : _vm.language == 2 ? items.enIntroduction : items.trIntroduction))]) : _vm._e(), _c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: items.showIntroduce,
        expression: "items.showIntroduce"
      }],
      on: {
        click: function ($event) {
          return _vm.readMore(index, 0);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-top"
    }), _vm._v(_vm._s(_vm.mapText.stow[parseInt(_vm.language - 1)]))])] : _vm._e()], 2);
  }), 1)], 1)]), _vm._m(2), _vm.device == "pc" ? _c("div", {
    ref: "honors_qualifications",
    staticClass: "honors-qualifications",
    attrs: {
      id: "honors_qualifications"
    }
  }, [_c("div", {
    ref: "honors_qualifications_title",
    staticClass: "honors-header animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.mapText.honors_qualifications[parseInt(_vm.language - 1)]))]), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    ref: "honors_qualifications_left",
    staticClass: "left animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "honor-titles",
    class: {
      "title-tag-en": _vm.language == 2
    }
  }, _vm._l(_vm.honorTitleList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "title-tag",
      class: {
        active: _vm.honorTitleIndex == index
      },
      on: {
        click: function ($event) {
          return _vm.selectHonorType(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name[parseInt(_vm.language - 1)]) + " ")]);
  }), 0)]), _c("div", {
    ref: "honors_qualifications_right",
    staticClass: "right animate__animated animate__slow display-none"
  }, [_c("el-row", {
    staticStyle: {
      display: "flex",
      "flex-wrap": "wrap"
    },
    attrs: {
      gutter: 8
    }
  }, _vm._l(_vm.honor, function (item, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 6
      }
    }, [_c("img", {
      attrs: {
        src: _vm.title + item.honorPic
      }
    })]);
  }), 1), _c("div", {
    ref: "honors_qualifications_circle",
    staticClass: "circle animate__animated animate__slow display-none"
  })], 1)])]) : _vm._e(), _vm.device == "mobile" ? _c("div", {
    ref: "honors_qualifications",
    staticClass: "honors-qualifications",
    attrs: {
      id: "honors_qualifications"
    }
  }, [_c("div", {
    ref: "honors_qualifications_title",
    staticClass: "honors-header animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.mapText.honors_qualifications[parseInt(_vm.language - 1)]))]), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    ref: "honors_qualifications_left",
    staticClass: "left animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "honor-titles",
    class: {
      "title-tag-en": _vm.language == 2
    }
  }, _vm._l(_vm.honorTitleList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "title-tag",
      class: {
        active: _vm.honorTitleIndex == index
      },
      on: {
        click: function ($event) {
          return _vm.selectHonorType(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name[parseInt(_vm.language - 1)]) + " ")]);
  }), 0)]), _c("div", {
    ref: "honors_qualifications_right",
    staticClass: "right animate__animated animate__slow display-none"
  }, [_c("el-row", {
    attrs: {
      gutter: 8
    }
  }, _vm._l(_vm.honor, function (item, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("img", {
      attrs: {
        src: _vm.title + item.honorPic
      }
    })]);
  }), 1), _c("div"), _c("div", {
    ref: "honors_qualifications_circle",
    staticClass: "circle animate__animated animate__slow display-none"
  })], 1)])]) : _vm._e(), false ? _c("div", {
    staticClass: "companyBlock",
    attrs: {
      id: "development_history"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("发展历程")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Milestones")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("發展歷程")]) : _vm._e(), _c("div", {
    staticClass: "line"
  })]), _c("img", {
    staticClass: "deveImg",
    attrs: {
      src: _vm.title + "uploadImage/" + _vm.developmentPic.developmentPic
    }
  }), _c("div", {
    staticClass: "box"
  }, [[_c("el-tabs", {
    attrs: {
      "tab-position": "top",
      type: "card"
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.development, function (items, index) {
    return _c("el-tab-pane", {
      attrs: {
        label: items.year,
        name: items.year
      }
    }, [_c("el-timeline", _vm._l(items.details, function (item, i) {
      return _c("el-timeline-item", {
        attrs: {
          placement: "top"
        }
      }, [_c("el-card", [_vm.language == 1 ? _c("h4", [_vm._v(_vm._s(item.month) + "月")]) : _vm._e(), _vm.language == 2 ? _c("h4", [_vm._v(_vm._s(item.month))]) : _vm._e(), _vm.language == 3 ? _c("h4", [_vm._v(_vm._s(item.month) + "月")]) : _vm._e(), _c("div", {
        domProps: {
          innerHTML: _vm._s(item.context)
        }
      })])], 1);
    }), 1)], 1);
  }), 1)], _c("div", {
    staticClass: "clearfix"
  })], 2)])]) : _vm._e(), false ? _c("div", {
    staticClass: "manager",
    attrs: {
      id: "management"
    }
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "managerImg",
    attrs: {
      src: require("../assets/manager.png")
    }
  }), _c("div", {
    staticClass: "managerInfos"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("管理团队")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Leadership")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("管理團隊")]) : _vm._e(), _c("ul", {
    staticClass: "managerInfos-text"
  }, [_vm._l(_vm.managementList, function (items, index) {
    return _vm.language == 1 ? _c("li", [_c("img", {
      staticClass: "headImg",
      attrs: {
        src: _vm.title + "uploadImage/" + items.headImage
      }
    }), _c("h3", {
      staticClass: "name"
    }, [_vm._v(_vm._s(items.cnName))]), _c("div", {
      staticClass: "position"
    }, [_c("pre", [_vm._v(_vm._s(items.cnPosition))])]), _c("div", {
      staticClass: "introduce"
    }, [_vm._v(_vm._s(items.cnIntroduction))])]) : _vm._e();
  }), _vm._l(_vm.managementList, function (items, index) {
    return _vm.language == 2 ? _c("li", [_c("img", {
      staticClass: "headImg",
      attrs: {
        src: _vm.title + "uploadImage/" + items.headImage
      }
    }), _c("h3", {
      staticClass: "name"
    }, [_vm._v(_vm._s(items.enName))]), _c("div", {
      staticClass: "position"
    }, [_c("pre", [_vm._v(_vm._s(items.enPosition))])]), _c("p", {
      staticClass: "introduce en-introduce"
    }, [_vm._v(_vm._s(items.enIntroduction))])]) : _vm._e();
  }), _vm._l(_vm.managementList, function (items, index) {
    return _vm.language == 3 ? _c("li", [_c("img", {
      staticClass: "headImg",
      attrs: {
        src: _vm.title + "uploadImage/" + items.headImage
      }
    }), _c("h3", {
      staticClass: "name"
    }, [_vm._v(_vm._s(items.trName))]), _c("div", {
      staticClass: "position"
    }, [_c("pre", [_vm._v(_vm._s(items.trPosition))])]), _c("div", {
      staticClass: "introduce"
    }, [_vm._v(_vm._s(items.trIntroduction))])]) : _vm._e();
  })], 2)])]) : _vm._e(), false ? _c("div", {
    staticClass: "qualification",
    attrs: {
      id: "company_honor"
    }
  }, [_vm.language == 1 ? _c("h2", [_vm._v("荣誉资质")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Qualification Honor")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("榮譽資質")]) : _vm._e(), _vm.language == 1 ? _c("ul", {
    staticClass: "qualificationMenu"
  }, [_c("li", {
    class: _vm.honorType == 1 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(1);
      }
    }
  }, [_vm._v("产品资质")]), _c("li", {
    class: _vm.honorType == 2 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(2);
      }
    }
  }, [_vm._v("生产资质")]), _c("li", {
    class: _vm.honorType == 3 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(3);
      }
    }
  }, [_vm._v("经营资质")]), _c("li", {
    class: _vm.honorType == 5 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(5);
      }
    }
  }, [_vm._v("实验室资质")]), _c("li", {
    class: _vm.honorType == 4 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(4);
      }
    }
  }, [_vm._v("社会荣誉")])]) : _vm._e(), _vm.language == 2 ? _c("ul", {
    staticClass: "qualificationMenu"
  }, [_c("li", {
    class: _vm.honorType == 1 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(1);
      }
    }
  }, [_vm._v("Product Qualification")]), _c("li", {
    class: _vm.honorType == 2 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(2);
      }
    }
  }, [_vm._v("Production Qualification")]), _c("li", {
    class: _vm.honorType == 3 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(3);
      }
    }
  }, [_vm._v("Business Qualification")]), _c("li", {
    class: _vm.honorType == 5 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(5);
      }
    }
  }, [_vm._v("Laboratory Qualification")]), _c("li", {
    class: _vm.honorType == 4 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(4);
      }
    }
  }, [_vm._v("Social Honor")])]) : _vm._e(), _vm.language == 3 ? _c("ul", {
    staticClass: "qualificationMenu"
  }, [_c("li", {
    class: _vm.honorType == 1 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(1);
      }
    }
  }, [_vm._v("産品資質")]), _c("li", {
    class: _vm.honorType == 2 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(2);
      }
    }
  }, [_vm._v("生産資質")]), _c("li", {
    class: _vm.honorType == 3 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(3);
      }
    }
  }, [_vm._v("經營資質")]), _c("li", {
    class: _vm.honorType == 5 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(5);
      }
    }
  }, [_vm._v("實驗室資質")]), _c("li", {
    class: _vm.honorType == 4 ? "active" : "",
    on: {
      click: function ($event) {
        return _vm.honorFun(4);
      }
    }
  }, [_vm._v("社會榮譽")])]) : _vm._e(), _c("div", {
    staticClass: "qualificationCon"
  }, [_c("ul", _vm._l(_vm.honor, function (items, index) {
    return _c("li", [_c("img", {
      attrs: {
        src: _vm.title + items.honorPic
      }
    })]);
  }), 0)])]) : _vm._e(), _c("div", {
    ref: "contact_us",
    staticClass: "contactUs contactBox contact-us",
    attrs: {
      id: "contact_us"
    }
  }, [_c("div", {
    ref: "contact_us_title",
    staticClass: "contact-header animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.mapText.contact_us[parseInt(_vm.language - 1)]))]), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    ref: "contact_us_content",
    staticClass: "col-main animate__animated animate__slow display-none"
  }, [_c("div", {
    staticClass: "contact_right"
  }, [_c("div", {
    staticClass: "contact_right-top j_Mouseover"
  }, [_vm.language == 1 ? _c("a", {
    class: _vm.showBtn == 1 ? "active" : "",
    on: {
      mouseover: function ($event) {
        _vm.showBtn = 1;
      }
    }
  }, [_vm._v("杭州")]) : _vm._e(), _vm.language == 1 ? _c("a", {
    class: _vm.showBtn == 2 ? "active" : "",
    on: {
      mouseover: function ($event) {
        _vm.showBtn = 2;
      }
    }
  }, [_vm._v("北京")]) : _vm._e(), _vm.language == 2 ? _c("a", {
    class: _vm.showBtn == 1 ? "active" : "",
    on: {
      mouseover: function ($event) {
        _vm.showBtn = 1;
      }
    }
  }, [_vm._v("Hangzhou")]) : _vm._e(), _vm.language == 2 ? _c("a", {
    class: _vm.showBtn == 2 ? "active" : "",
    on: {
      mouseover: function ($event) {
        _vm.showBtn = 2;
      }
    }
  }, [_vm._v("Beijing")]) : _vm._e(), _vm.language == 3 ? _c("a", {
    class: _vm.showBtn == 1 ? "active" : "",
    on: {
      mouseover: function ($event) {
        _vm.showBtn = 1;
      }
    }
  }, [_vm._v("杭州")]) : _vm._e(), _vm.language == 3 ? _c("a", {
    class: _vm.showBtn == 2 ? "active" : "",
    on: {
      mouseover: function ($event) {
        _vm.showBtn = 2;
      }
    }
  }, [_vm._v("北京")]) : _vm._e()]), _c("div", {
    staticClass: "contact_right_addre j_Mouseovershow"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBtn == 1,
      expression: "showBtn==1"
    }]
  }, [_c("ul", [_c("li", [_c("em", {
    staticClass: "el-icon-copy-document"
  }), _vm.language == 1 ? _c("span", [_vm._v("电话：0571-81107210"), _c("br"), _vm._v("电子邮件：IR@nhbio.com.cn")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("tel： 0571-81107210 "), _c("br"), _vm._v("Email： IR@nhbio.com.cn")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("電話： 0571-81107210 "), _c("br"), _vm._v("電子郵件： IR@nhbio.com.cn")]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("地址：浙江省杭州市滨江区长河街道江二路400号和瑞国际科技广场T1幢13层")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Address：13 / F, building T1, Herui international science and Technology Plaza, No. 400, jianger Road, Changhe street, Binjiang District, Hangzhou City, Zhejiang Province")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("地址：浙江省杭州市濱江區長河街道江二路400號和瑞國際科技廣場T1幢13層")]) : _vm._e()])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBtn == 2,
      expression: "showBtn==2"
    }]
  }, [_c("ul", [_c("li", [_c("em", {
    staticClass: "el-icon-copy-document"
  }), _vm.language == 1 ? _c("span", [_vm._v("电话：0571-81107210"), _c("br"), _vm._v("电子邮件：IR@nhbio.com.cn")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("tel： 0571-81107210 "), _c("br"), _vm._v("Email： IR@nhbio.com.cn")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("電話： 0571-81107210 "), _c("br"), _vm._v("電子郵件： IR@nhbio.com.cn")]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("地址：北京市昌平区回龙观镇中关村生命科学园生命园路8号院一区15幢4层")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Address：4 / F, building 15, zone 1, courtyard 8, shengmingyuan Road, Zhongguancun Life Science Park, Huilongguan town, Changping District, Beijing")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("地址：北京市昌平區回龍觀鎮中關村生命科學園生命園路8號院一區15幢4層")]) : _vm._e()])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBtn == 3,
      expression: "showBtn==3"
    }]
  }, [_c("ul", [_c("li", [_c("em", {
    staticClass: "el-icon-copy-document"
  }), _vm.language == 1 ? _c("span", [_vm._v("媒体联络：yuyuan.zhang@nhbio.com.cn"), _c("br"), _vm._v("投资者关系：lingge.liu@nhbio.com.cn"), _c("br"), _vm._v("招商联络: meng.yuan@nhbio.com.cn")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Media Relations： yuyuan.zhang@nhbio.com.cn "), _c("br"), _vm._v("Investor Relations： lingge.liu@nhbio.com.cn"), _c("br"), _vm._v("Business Relations：meng.yuan@nhbio.com.cn")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("媒體聯絡： yuyuan.zhang@nhbio.com.cn "), _c("br"), _vm._v("投資者關係： lingge.liu@nhbio.com.cn "), _c("br"), _vm._v("招商聯絡：meng.yuan@nhbio.com.cn")]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("地址：广州市越秀区中山六路232-258号越秀新都会大厦05F-128A室")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Address：WeWork 05-128A, NEO Metropolis, 232-258 Zhongshan 6th Road, Yuexiu District, Guangzhou")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("地址：廣州市越秀區中山六路232-258號越秀新都會塔樓05F-128A室")]) : _vm._e()])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBtn == 4,
      expression: "showBtn==4"
    }]
  }, [_c("ul", {
    staticClass: "wework"
  }, [_c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("北京"), _c("br"), _vm._v("北京市朝阳区建国路甲92号世茂大厦4层128室")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Beijing"), _c("br"), _vm._v("Room 128, 4th floor, Shimao building, No. a 92, Jianguo Road, Chaoyang District, Beijing")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("北京"), _c("br"), _vm._v("北京市朝陽區建國路甲92號世茂塔樓4層128室")]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("上海"), _c("br"), _vm._v("上海市徐汇区虹桥路3号港汇中心2座15楼1506室")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Shanghai"), _c("br"), _vm._v("Room 1506, 15 / F, block 2, ganghui center, No. 3, Hongqiao Road, Xuhui District, Shanghai")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("上海"), _c("br"), _vm._v("上海市徐匯區虹橋路3號港匯中心2座15樓1506室")]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("成都"), _c("br"), _vm._v("四川省成都市锦江区合江亭街道下东大街段199号睿东中心42层150室")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Chengdu"), _c("br"), _vm._v("Room 150, 42st floor, Ruidong center, No. 199, Xiadong Street section, Hejiangting street, Jinjiang District, Chengdu, Sichuan")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("成都"), _c("br"), _vm._v("四川省成都市錦江區合江亭街道下東大街段199號睿東中心42層150室")]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("广州"), _c("br"), _vm._v("广东省广州市越秀区北京街道中山五路33号大马站商业中心5层105室")]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Guangzhou"), _c("br"), _vm._v("Room 105, 5th floor, Damazhan commercial center, No. 33, Zhongshan 5th Road, Beijing street, Yuexiu District, Guangzhou City, Guangdong Province")]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("廣州"), _c("br"), _vm._v("廣東省廣州市越秀區北京街道中山五路33號大馬站商業中心5層105室")]) : _vm._e()])])])])]), _c("div", {
    staticClass: "contact_left"
  })])]), _c("div", {
    ref: "contact_us_bottom",
    staticClass: "col-social animate__animated animate__slow display-none",
    class: {
      "col-social-en": _vm.language == 2
    },
    attrs: {
      id: "honest"
    }
  }, [_vm.language == 1 ? _c("div", {
    staticClass: "col-social-left"
  }, [_c("img", {
    attrs: {
      src: require("../assets/sdasd.jpg")
    }
  })]) : _vm._e(), _vm.language == 1 ? _c("div", {
    staticClass: "col-social-right"
  }, [_vm._m(3), _c("p", {
    staticClass: "phone"
  }, [_vm._v("合规建议专线：（0571）88022307")]), _c("p", {
    staticClass: "email"
  }, [_vm._v("合规建议邮箱：compliance@nhbio.com.cn")])]) : _vm._e(), _vm.language == 2 ? _c("div", {
    staticClass: "col-social-left"
  }, [_c("img", {
    attrs: {
      src: require("../assets/sdasd_en.jpg")
    }
  })]) : _vm._e(), _vm.language == 2 ? _c("div", {
    staticClass: "col-social-right"
  }, [_vm._m(4), _c("p", {
    staticClass: "phone"
  }, [_vm._v("Compliance advice hotline：（0571）88022307")]), _c("p", {
    staticClass: "email"
  }, [_vm._v("Compliance advice email：compliance@nhbio.com.cn")])]) : _vm._e(), _vm.language == 3 ? _c("div", {
    staticClass: "col-social-left"
  }, [_c("img", {
    attrs: {
      src: require("../assets/sdasd_tr.jpg")
    }
  })]) : _vm._e(), _vm.language == 3 ? _c("div", {
    staticClass: "col-social-right"
  }, [_vm._m(5), _c("p", {
    staticClass: "phone"
  }, [_vm._v("合規建議專線：（0571）88022307")]), _c("p", {
    staticClass: "email"
  }, [_vm._v("合規建議郵箱：compliance@nhbio.com.cn")])]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "circle"
  }, [_c("div", {
    staticClass: "inner"
  }), _c("div", {
    staticClass: "outer"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "partition img-02"
  }, [_c("img", {
    attrs: {
      src: require("../assets/about_company/partition_img02.jpg"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "partition img-03"
  }, [_c("img", {
    attrs: {
      src: require("../assets/about_company/partition_img03.jpg"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_vm._v("尊敬的合作伙伴："), _c("br"), _vm._v("如您在与诺辉合作中，发现诺辉员工存在舞弊、贿赂、信息泄露、侵占资产等现象，欢迎及时与我们取得联系，感谢您的监督与举报！")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_vm._v("Dear partner："), _c("br"), _vm._v("If you find that there are fraud, bribery, information leakage, misappropriation of assets and other phenomena in your cooperation with nuohui, you are welcome to contact us in time. Thank you for your supervision and reporting!")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_vm._v("尊敬的合作夥伴："), _c("br"), _vm._v("如您在與諾輝合作中，發現諾輝員工存在舞弊、賄賂、資訊洩露、侵佔資產等現象，歡迎及時與我們取得聯系，感謝您的監督與舉報！")]);
}];
render._withStripped = true;
export { render, staticRenderFns };